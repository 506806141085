import React from "react"
import { Box, Text, SimpleGrid, Flex, Heading, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Feature1Image from "@/assets/images/userresearch/contribute/image-1.png"
import Feature2Image from "@/assets/images/userresearch/contribute/image-2.png"
import { headerHeight } from "@/utils/userresearch/constant"

const features = [
  { image: Feature1Image },
  { image: Feature2Image },
]

const Contribute = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      id="Contribute"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
      position="relative"
      backgroundColor="#FAFAFA"
    >
     <Heading size="2xl" textAlign="center" fontSize="32px" fontWeight="600" color="#003686"
      pt={{ md: "50px" }}
      pb={{ md: "20px" }}
      padding={{ base: "30px" }}
      >
          {t(`home-page.contribute.heading`)}
      </Heading>
      <Text fontFamily="Noto Sans" fontSize="16px" textAlign="center" fontWeight="500" alignItems="center">
          {t(
           `home-page.contribute.description`
          )}
      </Text>
      <Box mx="auto" maxW="4xl">
        <SimpleGrid
          px="6"
          py="20"
          columns={{ base: 1, md: 2 }}
          spacing="12"
          textAlign="center"
          pt="20px"
        >
          {features.map(({ image }, index) => (
            <Flex
              direction="column"
              key={`feature-${index + 1}`}
              alignItems="center"
              px="4"
              height="120%"
            >
              <Image
                src={image}
                alt={`${t(
                  `home-page.key-features-section.features.${index}.name`
                )} logo`}
                mb="35"
                mt="35"
                width={{ base: "338px", sm: "338px" }}
                height={{ base: "226px", sm: "226px" }}
              />

              <Text  fontSize="16px" fontWeight="400"
                 dangerouslySetInnerHTML={{__html: t(`home-page.contribute.features.${index}.name`)}}
              />
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default Contribute
